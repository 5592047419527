import {Injectable} from "@angular/core";
import {IArticle} from "@core/model";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  public articles: IArticle[] = [
    {
      title: 'The Evolution of Drum Organizers: From Manual Sorting to AI-Powered Tools',
      description: 'Drum organizers are essential tools for music producers and sound designers, helping them manage vast\n' +
        '    libraries of drum samples and loops. These tools have come a long way from their humble beginnings, evolving from\n' +
        '    manual sorting and categorization to AI-powered solutions that can automatically tag, organize, and search for\n' +
        '    sounds. With the rise of machine learning and artificial intelligence, drum organizers are becoming more\n' +
        '    sophisticated, intuitive, and efficient, enabling producers to spend less time searching for sounds and more time\n' +
        '    making music.',
      link: 'drum-organizer',
      image: 'assets/images/articles/drum-organizer.jpg'
    },
    {
      title: 'Should Music Genres Still Matter in Today’s Music World?',
      description: 'Music genres have long been a cornerstone of the music industry, helping listeners find new music and artists\n' +
        '    they love. But in today’s streaming age, where playlists and algorithms reign supreme, are genres still relevant?\n' +
        '    With the rise of cross-genre collaborations, genre-blending tracks, and the increasing popularity of playlists over\n' +
        '    albums, it’s clear that the traditional boundaries between rock, jazz, hip-hop, and other genres are blurring. So,\n' +
        '    should music genres still matter in today’s music world, or are they becoming obsolete?',
      link: 'music-genres',
      image: 'assets/images/articles/musicgenre.jpg'
    },
    {
      title: 'AI and the New Era of Music Creation: Where Machines and Musicians Meet',
      description: 'AI is rocking the music world, and it’s not just about algorithms spitting out random sounds. Imagine sitting down\n' +
        '    to listen to a new track and wondering, "Did a person compose this, or was it a computer?" This modern-day music\n' +
        '    mystery isn’t hypothetical; it’s already happening. Today’s AI models are powerful enough to generate music that\n' +
        '    even experts might mistake for something crafted by a human.',
      link: 'ai-music-2024',
      image: 'assets/images/articles/aimusic.jpg'
    },
    {
      title: 'What is an Audio Sample Chopper for Drums?',
      description: 'Audio sample chopping, or slicing, is an essential technique in music production,\n' +
        '    especially for drum tracks. It involves breaking down a continuous audio file into smaller, manageable clips or\n' +
        '    slices. Producers and musicians use this method to isolate different drum elements, such as kicks, snares, or\n' +
        '    hi-hats, making it easier to rearrange, modify, or layer them in a track. Whether you\'re creating hip-hop beats,\n' +
        '    electronic grooves, or custom drum patterns, chopping lets you creatively control individual drum sounds within a\n' +
        '    loop.',
      link: 'auto-slicer',
      image: 'assets/images/articles/auto-slicer.jpg'
    }
  ];
}
